import { Component, OnInit } from '@angular/core';
import { AuthService } from 'Extension/services/auth/auth.service';

@Component({
  selector: 'extension-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
  private isLogging;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.onAuthSubscribe();
  }

  public async login() {
    await this.authService.login();
  }

  public logout() {
    this.authService.logout();
  }

  private onAuthSubscribe() {
    this.authService.isAuthenticated$.subscribe((isAuth) => {
      this.isLogging = isAuth;
    });
  }

  get statusLogging() {
    return this.isLogging;
  }
}
