import axios, { AxiosAdapter } from 'axios';
import { setupCache } from 'axios-cache-adapter';
import { environment } from 'Environments/environment';
import { AuthService } from 'Extension/services/auth/auth.service';
import { DateTime } from 'luxon';
import { filter } from 'rxjs/operators';

const cache = setupCache({
  maxAge: 1 * 60 * 1000,
});

const authService = new AuthService();

const chuckRequest = axios.create({
  baseURL:
    'https://cors-anywhere.herokuapp.com/https://chucknorrisfacts.fr/api/get?data=tri:alea;nb:10;type:txt',
  timeout: 8000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

const callback = (data, resolve, reject) => {
  if (data && data.isError) {
    reject(data.isError);
  } else {
    resolve(data);
  }
};

const chuckRequestRuntime = {
  get: () =>
    axios
      .create({
        baseURL:
          'https://cors-anywhere.herokuapp.com/https://chucknorrisfacts.fr/api/get?data=tri:alea;nb:10;type:txt',
        timeout: 8000,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      })
      .get(''),
};

const PipeDriveRequest = axios.create({
  adapter: cache.adapter as AxiosAdapter,
  baseURL: environment.pipedriveEndpoint,
  timeout: 2000,
  params: {
    api_token: '',
  },
});

const NjoyRequest = axios.create({
  adapter: cache.adapter as AxiosAdapter,
  baseURL: environment.njoyEndpoint,
  timeout: 60 * 2000,
});

const formatParams = (params) => {
  if (params === null || params === undefined) return params;

  for (const [key, value] of Object.entries(params)) {
    for (const [param_key, param_value] of Object.entries(value)) {
      if (DateTime.isDateTime(param_value)) {
        params[key][param_key] = param_value.toString();
      }
    }
  }
  return params;
};

const NjoyRuntimeRequest = {
  get: (url, params?) =>
    new Promise<any>(async (resolve, reject) => {
      NjoyRequest.get(url, formatParams(params)).then((data) =>
        callback(data, resolve, reject),
      );
    }),
  getData: (url, params?) =>
    new Promise<any>((resolve, reject) => {
      NjoyRequest.get(url, {
        params: formatParams(params),
        responseType: 'blob',
      }).then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.data);
        reader.onloadend = () =>
          callback({ ...data, data: reader.result }, resolve, reject);
      });
    }),
  put: (url, params) =>
    new Promise<any>((resolve, reject) => {
      NjoyRequest.put(url, params).then((data) =>
        callback(data, resolve, reject),
      );
    }),
  patch: (url, params) =>
    new Promise<any>((resolve, reject) => {
      NjoyRequest.patch(url, params).then((data) =>
        callback(data, resolve, reject),
      );
    }),
  post: (url, params) =>
    new Promise<any>((resolve, reject) => {
      NjoyRequest.post(url, params).then((data) =>
        callback(data, resolve, reject),
      );
    }),
  postData: async (url, fileData) => {
    const fileUrl = await fetch(fileData.file);
    const arrayBuffer = await fileUrl.arrayBuffer();
    const file = new File([arrayBuffer], fileData.file_name, {
      type: fileData.file_type,
    });
    const formData = new FormData();
    formData.append('file', file);
    return new Promise<any>(async (resolve, reject) => {
      NjoyRequest.post(url, formData).then((data) =>
        callback(data, resolve, reject),
      );
    });
  },
  delete: (url) =>
    new Promise<any>((resolve, reject) => {
      NjoyRequest.delete(url).then((data) => callback(data, resolve, reject));
    }),
};

NjoyRequest.interceptors.request.use(
  (config) => {
    return new Promise((resolve) => {
      authService.accessTokenSubject$
        .pipe(filter((token) => token != null))
        .subscribe((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(config);
        });
    });
  },
  (error) => {
    // Do something with request error
    return error;
  },
);

const PipeDriveRuntimeRequest = {
  get: (url) =>
    new Promise<any>((resolve, reject) => {
      chrome.runtime.sendMessage(
        {
          contentScriptQuery: 'apiPipedrive',
          method: 'get',
          url,
        },
        (data) => callback(data, resolve, reject),
      );
    }),
};

PipeDriveRequest.interceptors.request.use(async (config) => ({
  ...config,
  params: {
    api_token: await new Promise((resolve) => {
      chrome.storage.sync.get(
        {
          pipedriveApiKey: '',
        },
        (items) => {
          resolve(items.pipedriveApiKey);
        },
      );
    }),
    ...config.params,
  },
}));

const MappySuggestRequest = axios.create({
  adapter: cache.adapter as AxiosAdapter,
  baseURL: 'https://suggest.mappy.net/suggest/1.2/',
  timeout: 2000,
});

const MappyRuntimeSuggestRequest = {
  get: (url) =>
    new Promise<any>((resolve, reject) => {
      axios.create({
        adapter: cache.adapter as AxiosAdapter,
        baseURL: 'https://suggest.mappy.net/suggest/1.2/',
        timeout: 2000,
      });
    }),
};

const MappyRoutemmRequest = axios.create({
  adapter: cache.adapter as AxiosAdapter,
  baseURL: 'https://routemm.mappy.net/multipath/6.0/',
  timeout: 2000,
});

const MappyRuntimeRoutemmRequest = {
  get: (url) =>
    new Promise<any>((resolve, reject) => {
      axios.create({
        adapter: cache.adapter as AxiosAdapter,
        baseURL: 'https://routemm.mappy.net/multipath/6.0/',
        timeout: 2000,
      });
    }),
};

const setDefaults = async () => {};

export {
  chuckRequest,
  chuckRequestRuntime,
  PipeDriveRequest,
  PipeDriveRuntimeRequest,
  NjoyRuntimeRequest,
  NjoyRequest,
  MappySuggestRequest,
  MappyRuntimeSuggestRequest,
  MappyRoutemmRequest,
  MappyRuntimeRoutemmRequest,
  setDefaults,
  callback,
  authService,
};
