import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'day' })
export class Day implements PipeTransform {
  transform(date) {
    return DateTime.fromISO(date)
      .setLocale('fr')
      .toFormat('EEEE');
  }
}
