import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';
import {
  PhoneNumberFormat as PNF,
  PhoneNumberUtil,
} from 'google-libphonenumber';

const PhoneUtil = PhoneNumberUtil.getInstance();

@Pipe({ name: 'formatPhone' })
export class FormatPhone implements PipeTransform {
  transform(phone) {
    const number = PhoneUtil.parseAndKeepRawInput(phone, 'FR');
    if (PhoneUtil.isValidNumber(number)) {
      return PhoneUtil.format(number, PNF.NATIONAL);
    }

    return phone;
  }
}
