import { DateTime } from 'luxon';

export default class DateInterval {
  public startDate: DateTime;
  public endDate: DateTime;

  public interval(startDate: DateTime, endDate: DateTime): void {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  public custom(uri: string, position: number): void {
    const dates = uri.split('/')[position];
    this.startDate = DateTime.fromFormat(
      dates.split('_')[1],
      'yyyy-MM-dd',
    ).startOf('day');
    this.endDate = DateTime.fromFormat(dates.split('_')[2], 'yyyy-MM-dd').endOf(
      'day',
    );
  }

  public today(): void {
    this.startDate = DateTime.local().startOf('day');
    this.endDate = DateTime.local().endOf('day');
  }

  public yesterday(): void {
    this.startDate = DateTime.local().minus({ days: 1 }).startOf('day');
    this.endDate = DateTime.local().minus({ days: 1 }).endOf('day');
  }

  public tomorrow(): void {
    this.startDate = DateTime.local().plus({ days: 1 }).startOf('day');
    this.endDate = DateTime.local().plus({ days: 1 }).endOf('day');
  }

  public week(): void {
    this.startDate = DateTime.local().startOf('week');
    this.endDate = DateTime.local().endOf('week');
  }

  public lastWeek(): void {
    this.startDate = DateTime.local().minus({ week: 1 }).startOf('week');
    this.endDate = DateTime.local().minus({ week: 1 }).endOf('week');
  }

  public nextWeek(): void {
    this.startDate = DateTime.local().plus({ week: 1 }).startOf('week');
    this.endDate = DateTime.local().plus({ week: 1 }).endOf('week');
  }

  public month(): void {
    this.startDate = DateTime.local().startOf('month');
    this.endDate = DateTime.local().endOf('month');
  }

  public lastMonth(): void {
    this.startDate = DateTime.local().minus({ month: 1 }).startOf('month');
    this.endDate = DateTime.local().minus({ month: 1 }).endOf('month');
  }

  public nextMonth(): void {
    this.startDate = DateTime.local().plus({ month: 1 }).startOf('month');
    this.endDate = DateTime.local().plus({ month: 1 }).endOf('month');
  }

  public quarter(): void {
    this.startDate = DateTime.local().startOf('quarter');
    this.endDate = DateTime.local().endOf('quarter');
  }

  public lastQuarter(): void {
    this.startDate = DateTime.local().minus({ quarter: 1 }).startOf('quarter');
    this.endDate = DateTime.local().minus({ quarter: 1 }).endOf('quarter');
  }

  public nextQuarter(): void {
    this.startDate = DateTime.local().plus({ quarter: 1 }).startOf('quarter');
    this.endDate = DateTime.local().plus({ quarter: 1 }).endOf('quarter');
  }

  public year(): void {
    this.startDate = DateTime.local().startOf('year');
    this.endDate = DateTime.local().endOf('year');
  }

  public lastYear(): void {
    this.startDate = DateTime.local().minus({ year: 1 }).startOf('year');
    this.endDate = DateTime.local().minus({ year: 1 }).endOf('year');
  }

  public getInterval(): { start: any; end: any } {
    return { start: this.startDate.toISO(), end: this.endDate.toISO() };
  }

  public getEncodedInterval(): { start: string; end: string } {
    return {
      start: encodeURIComponent(this.startDate.toISO()),
      end: encodeURIComponent(this.endDate.toISO()),
    };
  }
}
