export const sharedEnvironment = {
  production: true,
  staging: false,
  njoyEndpoint: 'https://api.njoy.fr',
  pipedriveEndpoint: 'https://njoy.pipedrive.com/v1',
  dealLink: 'https://njoy.pipedrive.com/deal/',
  noDealLink: 'https://njoy.pipedrive.com/deals/user/everyone',
  clientOrganizationLink: 'https://njoy.pipedrive.com/organization/',
  clientPersonLink: 'https://njoy.pipedrive.com/person/',
  auth0Domain: 'njoy-it.eu.auth0.com',
  auth0ClientId: '8KkYqJHMnNqtST95EP9FtjHy46vE4M9o',
  auth0Audience: 'https://api.njoy.fr',
  line: '',
  pipedriveApi: '',
};

export default sharedEnvironment;

