import { Pipe, PipeTransform } from '@angular/core';
import { IInventory } from 'Extension/models/IIventory.interface';

@Pipe({ name: 'filterInventoryByProduct' })
export class FilterInventoryByProduct implements PipeTransform {
  transform(value: IInventory[], product: string): IInventory[] {
    return value.filter(
      (inventory) => inventory.kit && inventory.kit.product_name === product,
    );
  }
}
