import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderByDate' })
export class OrderByDate implements PipeTransform {
  transform(array: any[], criteria: string) {
    return array.sort(
      (a, b) => +new Date(a[criteria]) - +new Date(b[criteria]),
    );
  }
}
