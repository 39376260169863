import { Injectable } from '@angular/core';
import { NjoyRuntimeRequest as NjoyRequest } from 'Utils/requests';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class DealsApiService {
  public async getDealFromPipedriveId(_id): Promise<any> {
    const { data } = await NjoyRequest.get(
      `/search/deals?ids.pipedrive_id=${_id}`,
    );

    return data[0];
  }

  public async getClientDeals(client_id): Promise<any> {
    const { data } = await NjoyRequest.get(
      `/search/deals?client_id=${client_id}&date=${JSON.stringify({
        $gte: encodeURIComponent(DateTime.local().minus({ months: 7 })),
      })}`,
    );
    return data;
  }

  public async getDealsToInvoice(params: {
    start: string;
    end: string;
    typology?: string;
    client?: string;
    invoiceStatus?: string;
  }) {
    let url = `/search/deals?serviceDate=${JSON.stringify({
      $gte: encodeURIComponent(params.start),
      $lte: encodeURIComponent(params.end),
    })}&type=invoice`;
    if (params.typology) url += `&typology=${params.typology}`;
    if (params.client) url += `&client_id=${params.client}`;
    if (params.invoiceStatus) url += `&invoice_status=${params.invoiceStatus}`;
    return await NjoyRequest.get(url);
  }

  public async updateInvoiceNumber(deal_id, invoice_number): Promise<void> {
    await NjoyRequest.put(`/deals/${deal_id}/invoice`, {
      invoice: {
        number: invoice_number,
      },
    });
  }

  public async updateAnniversaryTypology(deal_id, anniversary_typology) {
    await NjoyRequest.patch(`/deals/${deal_id}/anniversary-typology`, {
      anniversary_typology,
    });
  }

  public async downloadSelectedDealsInvoicingExport(deals: any[]) {
    return await NjoyRequest.get(
      `/deals/exportInvoicing?pipedriveIds=${deals.join(',')}`,
    );
  }
}
