import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClarityModule } from '@clr/angular';
import { NgxFlatpickrModule } from 'ngx-flatpickrjs';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MeterToKm } from 'Extension/services/pipes/meterToKm.pipe';
import { Day } from 'Extension/services/pipes/day.pipe';
import { ToFrench } from 'Extension/services/pipes/toFrench.pipe';
import { FormatTime } from 'Extension/services/pipes/formatTime.pipe';
import { FormatPhone } from 'Extension/services/pipes/formatPhone.pipe';
import { Euro } from 'Extension/services/pipes/euro.pipe';
import { MathCeil } from 'Extension/services/pipes/mathCeil.pipe';
import { OrderByDate } from 'Extension/services/pipes/orderByDate.pipe';
import { NonCompliantAnswer } from 'Extension/services/pipes/nonCompliantAnswer.pipe';
import { FilterInventoryByProduct } from 'Extension/services/pipes/filterInventoryByProduct.pipe';
import { InventoriesProductList } from 'Extension/services/pipes/inventoriesProductList.pipe';

import { SuggestAddressComponent } from 'Extension/components/formComponents/suggest-address/suggest-address.component';
import { DetailedAddressComponent } from 'Extension/components/formComponents/detailed-address/detailed-address.component';
// tslint:disable-next-line:max-line-length
import { StorehouseComponentComponent } from 'Extension/components/dashboard-animation/dashboard-filters/components/storehouse-component/storehouse-component.component';
import { HasPermissionDirective } from 'Extension/directives/hasPermission.directive';

import { DateComponent } from './date/date.component';
import { PanelComponent } from './panel/panel.component';
import { EmojiComponent } from './emoji/emoji.component';
import { WidgetStorehouseComponent } from './widget-storehouse/widget-storehouse.component';
import { ToolBoxComponent } from './tool-box/tool-box.component';
import { WidgetPhoneComponent } from './widget-phone/widget-phone.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { VehiclesComponent } from '../extension/components/dashboard-animation/dashboard-modals/components/vehicles/vehicles.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    ClarityModule,
    NgxFlatpickrModule,
    NgxSpinnerModule,
  ],
  declarations: [
    MeterToKm,
    ToFrench,
    Day,
    FormatTime,
    FormatPhone,
    Euro,
    MathCeil,
    OrderByDate,
    NonCompliantAnswer,
    SuggestAddressComponent,
    DetailedAddressComponent,
    StorehouseComponentComponent,
    HasPermissionDirective,
    AuthenticationComponent,
    DateComponent,
    PanelComponent,
    EmojiComponent,
    WidgetStorehouseComponent,
    ToolBoxComponent,
    WidgetPhoneComponent,
    VehiclesComponent,
    FilterInventoryByProduct,
    InventoriesProductList,
  ],
  exports: [
    MeterToKm,
    ToFrench,
    Day,
    FormatTime,
    FormatPhone,
    Euro,
    MathCeil,
    OrderByDate,
    NonCompliantAnswer,
    SuggestAddressComponent,
    DetailedAddressComponent,
    StorehouseComponentComponent,
    HasPermissionDirective,
    AuthenticationComponent,
    DateComponent,
    PanelComponent,
    EmojiComponent,
    WidgetStorehouseComponent,
    ToolBoxComponent,
    WidgetPhoneComponent,
    VehiclesComponent,
    FilterInventoryByProduct,
    InventoriesProductList,
  ],
})
export class SharedModule {}
