import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { AuthService } from './auth.service';
import jwt_decode from 'jwt-decode';
import { Observable, race } from 'rxjs';
import { delay, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardDashboardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return new Promise((resolve, reject) => {
      race(
        this.auth.isAuthenticated$.pipe(skip(1)),
        this.auth.isAuthenticated$.pipe(delay(3000)),
      ).subscribe((loggedIn) => {
        if (!loggedIn) {
          this.router.navigate(['blank-component/noAuthDashboard']);
          reject();
        } else {
          this.auth.accessTokenSubject$.subscribe((access_token) => {
            let arrayPermissions = [];
            if (access_token !== null) {
              arrayPermissions = jwt_decode(
                JSON.stringify(access_token, null, 2),
              ).permissions;
            }

            if (arrayPermissions.includes('read:dashboard_events')) {
              this.router.navigate(['blank-component/dashboard-animation']);
            } else {
              this.router.navigate(['']);
            }
          });
          resolve(true);
        }
      });
    });
  }
}
