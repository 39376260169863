import { Injectable } from '@angular/core';
import { MappySuggestRequest, MappyRoutemmRequest } from 'Utils/requests';

@Injectable({
  providedIn: 'root',
})
export class MappyService {
  async getCoordinates(address) {
    const { lat, lng } = (
      await MappySuggestRequest.get(
        `suggest?q=${address}&f=places&max_results=1`,
      )
    ).data.suggests[0];
    return { lat, lng };
  }

  async calculateDistance(from, to) {
    try {
      return (
        await MappyRoutemmRequest.get(
          `routes?from=${from.lng},${from.lat}&to=${to.lng},${to.lat}&providers=car&lang=fr_FR`,
        )
      ).data.car.routes[0];
    } catch (error) {
      console.error('MAPPY ERROR', error);
    }
  }
}
