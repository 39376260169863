import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CommonModule,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { BlankComponent } from 'Extension/layout/blank/blank.component';
import { AuthComponent } from 'Extension/layout/auth/auth.component';
import { AuthGuardService as AuthGuard } from 'Extension/services/auth/auth-guard.service';
import { AuthGuardDashboardService as AuthGuardDashboard } from 'Extension/services/auth/auth-guard-dashboard.service';
import { AuthGuardExtensionLinksService as AuthGuardExtensionLinks } from 'Extension/services/auth/auth-guard-extension-links.service';
import { AuthGuardDashboardLinksService as AuthGuardDashboardLinks } from 'Extension/services/auth/auth-guard-dashboard-links.service';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'noAuth',
        loadChildren: './components/no-auth/no-auth.module#NoAuthModule',
      },
      {
        path: 'search_availaible_kits',
        loadChildren:
          './components/available-kits/available-kits.module#AvailableKitsModule',
        canActivate: [AuthGuardExtensionLinks],
      },
      {
        path: 'book_kit',
        loadChildren: './components/book-kit/book-kit.module#BookKitModule',
        canActivate: [AuthGuardExtensionLinks],
      },
      {
        path: 'faq',
        loadChildren: './components/faq/faq.module#FaqModule',
        canActivate: [AuthGuardExtensionLinks],
      },
    ],
  },
  {
    path: 'blank-component',
    component: BlankComponent,
    children: [
      {
        path: '',
        redirectTo: '',
        pathMatch: 'full',
        canActivate: [AuthGuardDashboard],
      },
      {
        path: 'noAuthDashboard',
        loadChildren:
          './components/no-auth-dashboard/no-auth-dashboard.module#NoAuthDashboardModule',
      },
      {
        path: 'dashboard-animation',
        loadChildren:
          './components/dashboard-animation/dashboard-animation.module#DashboardAnimationModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-resources',
        loadChildren:
          './components/dashboard-resources/dashboard-resources.module#DashboardResourcesModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-kits',
        loadChildren:
          './components/dashboard-kits/dashboard-kits.module#DashboardKitsModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-storehouses',
        loadChildren:
          './components/dashboard-storehouses/dashboard-storehouses.module#DashboardStorehousesModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-vehicles',
        loadChildren:
          './components/dashboard-vehicles/dashboard-vehicles.module#DashboardVehiclesModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-kpi',
        loadChildren:
          './components/dashboard-kpi/dashboard-kpi.module#DashboardKpiModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-inventory',
        loadChildren:
          './components/dashboard-inventory/dashboard-inventory.module#DashboardInventoryModule',
        canActivate: [AuthGuardDashboardLinks],
      },
      {
        path: 'dashboard-invoice',
        loadChildren:
          './components/dashboard-invoice/dashboard-invoice.module#DashboardInvoiceModule',
        canActivate: [AuthGuardDashboardLinks],
      },
    ],
  },
];

export const extRouting = RouterModule.forRoot(routes);
@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  exports: [RouterModule],
  declarations: [],
})
export class ExtensionRoutingModule {}
