import { Injectable } from '@angular/core';
import { NjoyRuntimeRequest as NjoyRequest } from 'Utils/requests';

@Injectable({
  providedIn: 'root',
})
export class StorehousesApiService {
  constructor() {}

  public async getStorehouses(regex?) {
    const { data } = await NjoyRequest.get(
      `/storehouses${regex ? `?name=regex` : ''}`,
    );
    return data;
  }

  public async get(_id) {
    const { data } = await NjoyRequest.get(`/storehouses/${_id}`);
    return data;
  }

  public async add(storehouse) {
    return await NjoyRequest.post(`/storehouses`, storehouse);
  }

  public async update(storehouse) {
    return await NjoyRequest.put(`/storehouses/${storehouse._id}`, storehouse);
  }

  public async delete(_id) {
    return await NjoyRequest.delete(`/storehouses/${_id}`);
  }
}
