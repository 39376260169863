import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ClientsApiService } from 'Extension/services/api/clients/clients.service';
import call from 'Extension/services/utils/call';

@Component({
  selector: 'extension-widget-phone, [extension-widget-phone]',
  templateUrl: './widget-phone.component.html',
  styleUrls: ['./widget-phone.component.scss'],
})
export class WidgetPhoneComponent implements OnChanges {
  @Input() client: any;
  public phones: any[] = [];

  public async ngOnChanges(): Promise<void> {
    if (this.client) {
      this.phones = this.client.phones.filter(
        (phone) => phone.value && phone.value !== '',
      );
    }
  }

  public launchCall(phone: string) {
    // format phone  // format phone from pipedrive?
    call(phone);
  }
}
