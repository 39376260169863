import { Pipe, PipeTransform } from '@angular/core';
import { IInventory } from 'Extension/models/IIventory.interface';

@Pipe({ name: 'inventoriesProductList' })
export class InventoriesProductList implements PipeTransform {
  transform(inventories: IInventory[]): string[] {
    return inventories
      .reduce((acc, inventory) => {
        if (
          inventory.kit &&
          !acc.find((item) => item.name === inventory.kit.product_name)
        )
          acc.push({
            name: inventory.kit.product_name,
            color: inventory.kit.product_color,
          });
        return acc;
      }, [])
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
