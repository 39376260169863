import { Component } from '@angular/core';

@Component({
  selector: 'extension-root',
  templateUrl: './extension.component.html',
  styleUrls: ['./extension.component.scss'],
})
export class ExtensionComponent {
  constructor() {}
}
