import {
  Component,
  OnInit,
  forwardRef,
  OnDestroy,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Vehicle } from 'Extension/components/dashboard-vehicles/vehicle.model';
import { SearchAvailabilitiesApiService } from 'Extension/services/api/searchAvailabilities/search-availabilities.service';
import { contrast } from 'Extension/services/utils/contrast';
import { Subscription } from 'rxjs';

export const VEHICLES_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => VehiclesComponent),
  multi: true,
};

@Component({
  selector: 'extension-vehicles',
  templateUrl: './vehicles.component.html',
  providers: [VEHICLES_VALUE_ACCESSOR],
  styleUrls: ['./vehicles.component.scss'],
})
export class VehiclesComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() dates: { start: Date; end: Date };
  @Input() event_id: string;
  public vehiclesForm: FormGroup;
  public contrast = contrast;

  public vehiclesDatas: Vehicle[];

  private subscription: Subscription;
  private availableSubscription: Subscription;
  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;

  constructor(private availableService: SearchAvailabilitiesApiService) {
    this._onChange = (_: any) => {};
    this._onTouched = (_: any) => {};
    this.vehiclesForm = new FormGroup({
      available: new FormControl(false),
      vehicle_id: new FormControl(null),
    });
  }

  public async ngOnInit(): Promise<void> {
    this.subscription = this.vehiclesForm.valueChanges.subscribe((value) =>
      this._onChange(value),
    );

    this.availableSubscription = this.vehiclesForm
      .get('available')
      .valueChanges.subscribe((value) => {
        if (value === false) {
          this.vehiclesForm.get('vehicle_id').setValue(null);
        }
      });
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (
      changes &&
      changes.dates &&
      ((changes.dates.currentValue && changes.dates.firstChange) ||
        this.hasChanges(changes))
    ) {
      this.vehiclesDatas = await this.availableService.getAvailableVehicule(
        changes.dates.currentValue,
        this.event_id || null,
      );
    }
  }

  private hasChanges(changes: SimpleChanges): boolean {
    return (
      changes.dates.currentValue.start !== changes.dates.previousValue.start ||
      changes.dates.currentValue.end !== changes.dates.previousValue.end
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.availableSubscription) {
      this.availableSubscription.unsubscribe();
    }
  }

  public writeValue(value: any): void {
    if (value != null) {
      this.vehiclesForm.get('available').setValue(value.available);
      this.vehiclesForm.get('vehicle_id').setValue(value.vehicle_id);
    }
  }

  public registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
}
