import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, race } from 'rxjs';
import { AuthService } from './auth.service';
import { delay, skip } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardExtensionLinksService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return new Promise((resolve, reject) => {
      if (this.auth.accessToken != null) {
        resolve(true);
      }
      race(
        this.auth.isAuthenticated$.pipe(skip(1)),
        this.auth.isAuthenticated$.pipe(delay(3000)),
      ).subscribe((loggedIn) => {
        if (!loggedIn) {
          this.router.navigate(['']);
          reject();
        } else {
          resolve(true);
        }
      });
    });
  }
}
