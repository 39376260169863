import { Injectable } from '@angular/core';
import { environment } from 'Environments/environment';
import { IInventory } from 'Extension/models/IIventory.interface';
import { NjoyRuntimeRequest as NjoyRequest } from 'Utils/requests';
import * as FileSaver from 'file-saver';
import { AuthService } from 'Extension/services/auth/auth.service';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class EventsApiService {
  constructor(private authService: AuthService) {}

  public async getAdvancedEvents(value) {
    const { data } = await NjoyRequest.get('/dashboard/events', {
      params: value,
    });
    return data;
  }

  public async getEventsLinkToDeal(_id) {
    const { data } = await NjoyRequest.get(
      `/search/events?deals.deal_id=${_id}`,
    );
    return data;
  }

  public async getClientEvents(client) {
    const { data } = await NjoyRequest.get(
      `/search/events?client_id=${client._id}`,
    );
    return data;
  }

  public async getClientEventsByProduct(client, product) {
    const { data } = await NjoyRequest.get(
      `/search/events?client_id=${client._id}&kit_id=${JSON.stringify({
        $in: product.kits.map((kit) => kit._id),
      })}`,
    );

    return data;
  }

  public async getIntercenterEventsByProduct(product) {
    const { data } = await NjoyRequest.get(
      `/search/events?kit_id=${JSON.stringify({
        $in: product.kits.map((kit) => kit._id),
      })}&intercenter=${JSON.stringify({
        $gte: 2,
      })}`,
    );

    return data;
  }

  public async linkEventToDeal(event_id, deal_id, hash) {
    return await NjoyRequest.patch(`/events/${event_id}/linkevent`, {
      deal_id,
      hash,
    });
  }

  public async unlinkEventToDeal(event_id, deal_id, hash) {
    return await NjoyRequest.patch(`/events/${event_id}/unlinkevent`, {
      deal_id,
      hash,
    });
  }

  public async updateNPSPaidStatus(event_id, nps) {
    return await NjoyRequest.patch(`/events/${event_id}/nps`, {
      nps,
    });
  }

  public async postEvent(event) {
    return await NjoyRequest.post('/events', event);
  }

  public async updateEvent(event_id, body) {
    return await NjoyRequest.put(`/events/${event_id}`, body);
  }

  public async updateEventAnimators(event_id, animators) {
    return await NjoyRequest.patch(`/events/${event_id}/animators`, {
      animators,
    });
  }

  public async updateEventVehicle(event_id, vehicle) {
    return await NjoyRequest.patch(`/events/${event_id}/vehicle`, {
      vehicle,
    });
  }

  public async deleteEvent(_id) {
    return await NjoyRequest.delete(`/events/${_id}`);
  }

  public async resetSatisfactionSurvey(_id): Promise<void> {
    await NjoyRequest.patch(`/events/${_id}/resetSatisfactionSurvey`, {});
  }

  public async updateEventControl(event_id, is_control) {
    return await NjoyRequest.patch(`/events/${event_id}/control`, {
      is_control,
    });
  }

  public async updateContractsStatus(event_id, contracts_status) {
    return await NjoyRequest.patch(`/events/${event_id}/contracts`, {
      contracts_status,
    });
  }

  public async getInventories(params): Promise<IInventory[]> {
    const { data } = await NjoyRequest.get(`/inventories`, { params });
    return data;
  }

  public async exportInventories(params): Promise<void> {
    const { data } = await NjoyRequest.getData(`/inventories-export`, {
      ...params,
      start: DateTime.fromISO(params.start).toJSDate(),
      end: DateTime.fromISO(params.end).toJSDate(),
    });

    FileSaver.saveAs(
      data,
      `export_inventory_${DateTime.fromISO(params.start).toFormat(
        'dd/MM/yyyy',
      )}_${DateTime.fromISO(params.end).toFormat('dd/MM/yyyy')}_${
        params.type === 'before' ? 'depart' : 'retour'
      }.xlsx`,
    );
  }
}
