export const trad = {
  won: 'Gagnée',
  open: 'Ouverte',
  lost: 'Perdue',
  Collectivity: 'Collectivité',
  Business: 'Entreprise',
  Anniversary: 'Anniversaire',
  Stay: 'Séjour',
  no_typology: 'Pas de typologie',
  no_region: 'Pas de région',
  indicative: 'Devis indicatif',
  converted: 'Devis transformé',
  signed: 'Devis signé',
  null: '',
  true: 'Controlé !',
  false: 'Non controlé !',
  nodeal: "Pas d'offre",
  planned: 'prévu',
  realized: 'réalisé',
  work: 'Travail',
  mobile: 'Portable',
  all: 'Tout',
  not_sent: 'Pas envoyé',
  sent: 'Envoyé',
  sign: 'Signé',
  NOT_INVOICED: 'Non facturé',
  INVOICED: 'Facturé',
  PAID: 'Payé',
};
