import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nonCompliantAnswer' })
export class NonCompliantAnswer implements PipeTransform {
  transform(value: string | boolean | string[]): boolean {
    switch (true) {
      case typeof value === 'string':
        return value != null && (value as string).match(/ok/i) == null;
      case typeof value === 'object':
        return !!(value as string[]).find((val) => val.match(/ok/i) == null);
      case typeof value === 'boolean':
        return false;
    }
  }
}
