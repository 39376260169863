import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatTime' })
export class FormatTime implements PipeTransform {
  transform(time) {
    const hours = Math.trunc(time / 60);
    const minutes = time - hours * 60;
    return `${hours === 0 ? `` : `${hours}h`}${
      minutes < 10 ? `0${minutes}` : `${minutes}`
    }min`;
  }
}
