import {
  Component,
  Input,
  EventEmitter,
  Output,
  OnChanges,
} from '@angular/core';
import { inAlphabeticalOrder } from 'Extension/services/utils/inAlphabeticalOrder';
import { contrast } from 'Extension/services/utils/contrast';
import { Subject } from 'rxjs';

@Component({
  selector: 'extension-storehouse-component',
  templateUrl: './storehouse-component.component.html',
  styleUrls: ['./storehouse-component.component.scss'],
})
export class StorehouseComponentComponent implements OnChanges {
  @Input() storehousesFilterValue;
  @Input() storehousesFilterOptions;
  @Output() storehousesFilterValueChange = new EventEmitter<any>();

  public contrast = contrast;

  public storehousesData: Subject<any[]> = new Subject();
  public storehouses: any;

  public ngOnChanges(): void {
    this.loadStorehouses();
    this.setStorehouses();
  }

  public setStorehouses(): void {
    this.storehouses = this.storehousesFilterValue;
  }

  public onChangeStorehouses(storehouses): void {
    this.storehousesFilterValueChange.emit(storehouses);
  }

  private loadStorehouses(): void {
    this.storehousesData.next(
      this.storehousesFilterOptions.sort(inAlphabeticalOrder) || [],
    );
  }
}
