import { Component, OnInit } from '@angular/core';
import { AuthService } from 'Extension/services/auth/auth.service';
import { INavItem } from '../NavItem.interface';
import { Router } from '@angular/router';
import { environment } from 'Environments/environment';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'extension-blank',
  templateUrl: './blank.component.html',
  styleUrls: ['./blank.component.scss'],
})
export class BlankComponent implements OnInit {
  public navLinks: INavItem[];
  public dropDownLinks: INavItem[];
  public extensionID;
  public extensionLink;

  public isLogging: boolean;
  public isLogging$: any;
  public isOpen = false;

  public staging: boolean = environment.staging;

  constructor(
    private authService: AuthService,
    public router: Router,
    private sanitizer: DomSanitizer,
  ) {
    this.isLogging$ = authService.isAuthenticated$;
    this.navLinks = [
      {
        label: 'Animations',
        shape: 'animation',
        link: 'dashboard-animation',
        permissions: ['ui:events'],
        index: 0,
      },
      {
        label: 'Animateurs',
        shape: 'animateur',
        link: 'dashboard-resources',
        permissions: ['ui:resources'],
        index: 1,
      },
      {
        label: 'KPI',
        shape: 'kpi',
        link: 'dashboard-kpi',
        permissions: ['ui:kpi'],
        index: 2,
      },
      {
        label: 'Inventaires',
        shape: 'inventory',
        link: 'dashboard-inventory',
        permissions: ['ui:inventory'],
        index: 3,
      },
      {
        label: 'Facturation',
        shape: 'invoice',
        link: 'dashboard-invoice',
        permissions: ['ui:invoice'],
        index: 4,
      },
    ];

    this.dropDownLinks = [
      {
        label: 'Kits',
        shape: 'kit',
        link: 'dashboard-kits',
        permissions: ['ui:kits'],
        index: 0,
      },
      {
        label: 'Entrepôts',
        shape: 'entrepot',
        link: 'dashboard-storehouses',
        permissions: ['ui:storehouses'],
        index: 1,
      },
      {
        label: 'Véhicules',
        shape: 'vehicle',
        link: 'dashboard-vehicles',
        permissions: ['ui:vehicles'],
        index: 2,
      },
    ];
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.checkLoggingStatus();

    if (environment.isExtension) {
      this.extensionID = chrome.i18n.getMessage('@@extension_id');
      this.extensionLink = this.sanitizer.bypassSecurityTrustUrl(
        `chrome-extension://${this.extensionID}/index.html`,
      );
    } else {
      this.extensionLink = '#';
    }
  }

  checkLoggingStatus(): void {
    this.isLogging$.subscribe((value) => {
      this.isLogging = value;
      if (!value) {
        this.router.navigate(['blank-component']);
      }
    });
  }
}
