import {
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[dropdown]',
  exportAs: 'dropdown',
})
export class DropDownDirective {
  constructor(private elem: ElementRef, private renderer: Renderer2) {}

  @HostListener('document:click', ['$event.target'])
  public click(targetElement) {
    const toggle = document.querySelector('.dropdown');
    if (
      !this.elem.nativeElement.classList.contains('open') &&
      toggle.contains(targetElement)
    ) {
      this.renderer.addClass(this.elem.nativeElement, 'open');
    } else {
      this.renderer.removeClass(this.elem.nativeElement, 'open');
    }
  }
}
