import { Injectable } from '@angular/core';
import { Vehicle } from 'Extension/components/dashboard-vehicles/vehicle.model';
import { NjoyRuntimeRequest as NjoyRequest } from 'Utils/requests';

@Injectable({
  providedIn: 'root',
})
export class SearchAvailabilitiesApiService {
  constructor() {}

  public async searchAvailableKits({
    startDate,
    endDate,
    address,
    product,
    regex,
    currentEvent,
  }) {
    const params = {
      start: startDate,
      end: endDate,
      client_lat: address.lat,
      client_lng: address.lng,
      ...(product ? { product: product._id } : null),
      ...(currentEvent ? { event_id: currentEvent } : null),
      regex,
    };
    const { data } = await NjoyRequest.get('/search/availableKits', { params });

    return data;
  }

  public async getAvailableVehicule(dates, event_id): Promise<Vehicle[]> {
    const params = {
      start: dates.start,
      end: dates.end,
      event_id,
    };
    const { data } = await NjoyRequest.get(`/search/availableVehicles`, {
      params,
    });
    return data;
  }

  public async searchAvailableAnimators(regex, startDate, endDate, event_id?) {
    const params = {
      regex,
      start: startDate,
      end: endDate,
      event_id,
    };

    const { data } = await NjoyRequest.get('/search/availableAnimators', {
      params,
    });

    return data;
  }

  public async isKitAvailable(start, end, kit_id) {
    return await NjoyRequest.get(
      `/search/events?start=${JSON.stringify({
        $gte: encodeURIComponent(start),
        $lte: encodeURIComponent(end),
      })}&kit_id=${kit_id}`,
    );
  }
}
