import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
  OnInit,
} from '@angular/core';
import { AuthService } from 'Extension/services/auth/auth.service';
import jwt_decode from 'jwt-decode';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[hasPermission]',
  // tslint:disable-next-line:no-outputs-metadata-property
  outputs: ['[hasPermission]'],
})
export class HasPermissionDirective implements OnInit {
  private permissions = [];
  private logicalOp = 'AND';
  private isHidden = true;
  private arrayPermissions: [];

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.authService.accessTokenSubject$.subscribe((access_token) => {
      this.arrayPermissions = jwt_decode(
        JSON.stringify(access_token, null, 2),
      ).permissions;
      this.updateView();
    });
  }

  @Input()
  set hasPermission(val) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionOperation(op) {
    this.logicalOp = op;
    this.updateView();
  }

  private updateView() {
    if (this.checkPermission() || this.permissions.length === 0) {
      if (this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  private checkPermission() {
    let hasPermission = false;

    if (this.arrayPermissions) {
      for (const checkPermission of this.permissions) {
        const permissionFound = this.arrayPermissions.find(
          (x) => x === checkPermission,
        );

        if (!permissionFound && this.logicalOp === 'EXCEPT') {
          hasPermission = true;
          break;
        }

        if (permissionFound && this.logicalOp !== 'EXCEPT') {
          hasPermission = true;

          if (this.logicalOp === 'OR') {
            break;
          }
        } else {
          hasPermission = false;
          if (this.logicalOp === 'AND') {
            break;
          }
        }
      }
    }
    return hasPermission;
  }
}
