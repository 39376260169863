import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  getAPIAddressDataGouv(val) {
    return this.http
      .get<any>(`https://api-adresse.data.gouv.fr/search/?q=${val}`)
      .pipe((results) => {
        return results;
      });
  }
}
