const contrast = (color) => {
  // check if we are receiving an element or element background-color
  if (typeof color !== 'string') {
    return;
  }

  const hex = color.replace('#', '');
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const c =
    (Math.round(r * 299) + Math.round(g * 587) + Math.round(b * 114)) / 1000;

  return c >= 105 ? 'black' : 'white';
};

export { contrast };
