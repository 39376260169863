import { DateTime } from 'luxon'

export const periods = [
  {
    value: 'today',
    name: 'Aujourd\'hui',
    start: DateTime.local().startOf('day'),
    end: DateTime.local().endOf('day'),
  },
  {
    value: 'yesterday',
    name: 'Hier',
    start: DateTime.local().minus({ days: 1 }).startOf('day'),
    end: DateTime.local().minus({ days: 1 }).endOf('day'),
  },
  {
    value: 'tomorrow',
    name: 'Demain',
    start: DateTime.local().plus({ days: 1 }).startOf('day'),
    end: DateTime.local().plus({ days: 1 }).endOf('day'),
  },
  {
    value: 'week',
    name: 'Cette semaine',
    start: DateTime.local().startOf('week'),
    end: DateTime.local().endOf('week'),
  },
  {
    value: 'lastWeek',
    name: 'La semaine dernière',
    start: DateTime.local().minus({ week: 1 }).startOf('week'),
    end: DateTime.local().minus({ week: 1 }).endOf('week'),
  },
  {
    value: 'nextWeek',
    name: 'La semaine prochaine',
    start: DateTime.local().plus({ week: 1 }).startOf('week'),
    end: DateTime.local().plus({ week: 1 }).endOf('week'),
  },
  {
    value: 'month',
    name: 'Ce mois-ci',
    start: DateTime.local().startOf('month'),
    end: DateTime.local().endOf('month'),
  },
  {
    value: 'lastMonth',
    name: 'Le mois dernier',
    start: DateTime.local().minus({ month: 1 }).startOf('month'),
    end: DateTime.local().minus({ month: 1 }).endOf('month'),
  },
  {
    value: 'nextMonth',
    name: 'Le mois prochain',
    start: DateTime.local().plus({ month: 1 }).startOf('month'),
    end: DateTime.local().plus({ month: 1 }).endOf('month'),
  },
  {
    value: 'quarter',
    name: 'Ce trimestre',
    start: DateTime.local().startOf('quarter'),
    end: DateTime.local().endOf('quarter'),
  },
  {
    value: 'lastQuarter',
    name: 'Le trimestre précédent',
    start: DateTime.local().minus({ quarter: 1 }).startOf('quarter'),
    end: DateTime.local().minus({ quarter: 1 }).endOf('quarter'),
  },
  {
    value: 'nextQuarter',
    name: 'Le trimestre suivant',
    start: DateTime.local().plus({ quarter: 1 }).startOf('quarter'),
    end: DateTime.local().plus({ quarter: 1 }).endOf('quarter'),
  },
  {
    value: 'custom',
    name: 'Personnaliser la période',
  }
];
