import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {
  from,
  Observable,
  combineLatest,
  Subject,
  BehaviorSubject,
} from 'rxjs';
import { shareReplay, flatMap } from 'rxjs/operators';
import { environment } from 'Environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public accessTokenSubject$: BehaviorSubject<string> = new BehaviorSubject(
    null,
  );
  public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  private loginEvent$: Subject<any> = new Subject();

  public get accessToken() {
    return this.accessTokenSubject$.getValue();
  }

  private auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth0Domain,
      client_id: environment.auth0ClientId,
      redirect_uri: `${window.location.origin}/index.html`,
      audience: environment.auth0Audience,
    }),
  );

  public userProfile$: Observable<any> = combineLatest(
    this.auth0Client$,
    this.accessTokenSubject$.asObservable(),
    (client, token) => {
      return from(client.getUser());
    },
  ).pipe(
    flatMap((userProfile) => userProfile),
    shareReplay(1),
  );

  constructor() {
    this.loginEvent$.subscribe(async (event) => {
      const client = await this.auth0Client$.toPromise();
      switch (event.type) {
        case 'loginWithPopup':
          await client.loginWithPopup({
            redirect_uri: `${window.location.origin}/index.html`,
            appState: { target: event.redirectPath },
          });
          break;
        case 'logout':
          await client.logout({
            client_id: environment.auth0ClientId,
            returnTo: `${window.location.origin}/index.html`,
          });
          return;
      }
      this.accessTokenSubject$.next(await client.getTokenSilently());
      this.isAuthenticated$.next(true);
    });

    this.init();
  }

  init() {
    this.loginEvent$.next({ type: 'init' });
    this.accessTokenSubject$.subscribe();
    this.isAuthenticated$.subscribe();
  }

  login(redirectPath: string = '/') {
    this.loginEvent$.next({ type: 'loginWithPopup', redirectPath });
  }

  logout() {
    this.loginEvent$.next({ type: 'logout' });
  }
}
