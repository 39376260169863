import { Component, Input } from '@angular/core';

@Component({
  selector: 'extension-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent {
  @Input() title: string = null;
  @Input() totalCA: number;
  @Input() total: number;
}
