import { Injectable } from '@angular/core';
import { NjoyRuntimeRequest as NjoyRequest } from 'Utils/requests';

@Injectable({
  providedIn: 'root',
})
export class ProductsApiService {
  constructor() {}

  public async getProducts() {
    const { data } = await NjoyRequest.get(`/products`);
    return data;
  }

  public async getProductsWithKits() {
    const products = await this.getProducts();
    return products.filter(
      (product) =>
        product.kits &&
        product.kits.length &&
        product.kits.find((kit) => kit.state === true),
    );
  }

  public async getProduct(_id: string) {
    const { data } = await NjoyRequest.get(`/products/${_id}`);
    return data;
  }

  public async searchProducts(regex) {
    const { data } = await NjoyRequest.get(
      `/search/products?name=${JSON.stringify({
        $regex: regex,
      })}`,
    );
    return data;
  }

  public async getProductFromKit(kit_id) {
    const { data } = await NjoyRequest.get(
      `/search/products?kits._id=${kit_id}`,
    );

    return data[0];
  }

  public async getKits() {
    const { data } = await NjoyRequest.get(`/kits?${Date.now()}`);
    return data;
  }

  public async addKit(product_id, kit) {
    await NjoyRequest.post(`/kits`, { product_id, kit });
  }

  public async patchKitState(kit_id, state) {
    await NjoyRequest.patch(`/kits/${kit_id}/state`, { state });
  }

  public async patchKitStorehouse(kit_id, storehouse) {
    await NjoyRequest.patch(`/kits/${kit_id}/storehouse`, { storehouse });
  }

  public async patchKitRef(kit_id, ref) {
    await NjoyRequest.patch(`/kits/${kit_id}/ref`, { ref });
  }

  public async addKitUnavailability(kit_id, dates) {
    await NjoyRequest.post(`/kits/${kit_id}/unavailability`, { dates });
  }

  public async editKitUnavailability(kit_id, unavailability, dates) {
    await NjoyRequest.patch(`/kits/${kit_id}/unavailability/edit`, {
      unavailability,
      dates,
    });
  }

  public async deleteKitUnavailability(kit_id, unavailability) {
    await NjoyRequest.patch(
      `/kits/${kit_id}/unavailability/delete`,
      unavailability,
    );
  }

  public async deleteKit(kit_id) {
    await NjoyRequest.delete(`/kits/${kit_id}`);
  }
}
