import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ClientsApiService } from 'Extension/services/api/clients/clients.service';
import { StorehousesApiService } from 'Extension/services/api/storehouses/storehouses.service';
import { MappyService } from 'Extension/services/mappy/mappy.service';
import { contrast } from 'Extension/services/utils/contrast';

@Component({
  selector: 'extension-widget-storehouse, [extension-widget-storehouse]',
  templateUrl: './widget-storehouse.component.html',
  styleUrls: ['./widget-storehouse.component.scss'],
})
export class WidgetStorehouseComponent implements OnChanges {
  @Input() client: any;
  public contrast = contrast;
  public storehouses: any;

  constructor(
    private storehouseService: StorehousesApiService,
    private mappyService: MappyService,
  ) {}

  public async ngOnChanges(): Promise<void> {
    if (this.client) {
      this.storehouses = (
        await this.getStorehousesWithDistanceFromClient(this.client)
      )
        .sort((a, b) => a.distance - b.distance)
        .slice(0, 3);
    }
  }

  public async getStorehousesWithDistanceFromClient(
    client,
  ): Promise<{ storehouse: any; distance: number }[]> {
    const storehouses = await this.storehouseService.getStorehouses();
    return await Promise.all(
      storehouses.map(async (storehouse) => {
        const { length: distance } = await this.mappyService.calculateDistance(
          client.address,
          storehouse.address,
        );
        return {
          storehouse,
          distance,
        };
      }),
    );
  }

  public getOpacity(color) {
    return `${color}80`;
  }

  public calculateDistance(distance: number) {
    const roundTrip = distance * 2;
    return roundTrip + roundTrip * 0.1;
  }
}
