import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Instance } from 'flatpickr/dist/types/instance';
import DateInterval from './DateInteval';
import { DateTime, Interval } from 'luxon';
import { periods } from './Periods';
import { Subject, Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { IDates } from './dates.interface';

@Component({
  selector: 'extension-dates',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
})
export class DateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() dates: IDates;
  @Output() datesChange: EventEmitter<IDates>;

  datePickerOptions: object;
  startDatePickerInstance: Instance;
  endDatePickerInstance: Instance;

  searchEventsForm = new FormGroup({
    startDate: new FormControl(null),
    endDate: new FormControl(null),
  });
  formSubscribe: Subscription;

  periods: any[] = periods;
  fixedDateSelected = [];
  fixedDate = null;
  dateInterval = new Subject<DateInterval>();
  startDate: DateTime;
  endDate: DateTime;

  constructor() {
    this.datesChange = new EventEmitter<IDates>();
  }

  public ngOnInit(): void {
    this.datePickerOptions = {
      dateFormat: 'd F Y',
    };

    this.onSearchEventsFormChange();
    this.onFixedDateSelected();
  }

  public ngOnDestroy(): void {
    this.formSubscribe.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      this.dates &&
      !isEqual(changes.dates.currentValue, changes.dates.previousValue)
    ) {
      this.initDatesFields(this.dates);
    }
  }

  public initDatesFields(dates): void {
    const selectValue = periods.find((period) => {
      if (period.start && period.end) {
        const diffStart = period.start.diff(DateTime.fromISO(dates.start));
        const diffEnd = period.end.diff(DateTime.fromISO(dates.end));

        if (
          diffStart.values.milliseconds === 0 &&
          diffEnd.values.milliseconds === 0
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

    if (selectValue) {
      this.fixedDate = selectValue;
      this.startDate = DateTime.fromISO(selectValue.start).toJSDate();
      this.endDate = DateTime.fromISO(selectValue.end).toJSDate();
    } else {
      this.fixedDate = { name: 'Personnaliser la période' };
      this.startDate = DateTime.fromISO(dates.start).toJSDate();
      this.endDate = DateTime.fromISO(dates.end).toJSDate();
    }
  }

  public onSearchEventsFormChange(): void {
    this.formSubscribe = this.searchEventsForm.valueChanges.subscribe(
      async (value) => {
        let interval = null;
        if (value.startDate && value.endDate) {
          interval = Interval.fromDateTimes(
            DateTime.fromISO(value.startDate.split('+')[0]),
            DateTime.fromISO(value.endDate.split('+')[0]),
          )
            .toDuration(['months', 'days'])
            .toObject();

          if (
            value.startDate < value.endDate &&
            interval &&
            (interval.months < 3 ||
              (interval.months === 3 && interval.days < 1))
          ) {
            this.datesChange.emit({
              start: value.startDate,
              end: value.endDate,
            });
          }
        }
      },
    );
  }

  public onFixedDateSelected(): void {
    this.dateInterval.subscribe((value) => {
      this.searchEventsForm
        .get('startDate')
        .setValue(value.startDate.startOf('day').toISO());
      this.searchEventsForm
        .get('endDate')
        .setValue(value.endDate.endOf('day').toISO());
    });
  }

  public setStartDate(date): void {
    if (date && date[0]) {
      this.searchEventsForm
        .get('startDate')
        .setValue(DateTime.fromJSDate(date[0]).startOf('day').toISO());

      if (this.endDatePickerInstance) {
        this.endDatePickerInstance.set('minDate', date[0]);
        this.endDatePickerInstance.set(
          'maxDate',
          DateTime.fromJSDate(date[0]).plus({ month: 3 }).toJSDate(),
        );
      }
    }
  }

  public setEndDate(date): void {
    if (date && date[0]) {
      this.searchEventsForm
        .get('endDate')
        .setValue(DateTime.fromJSDate(date[0]).endOf('day').toISO());
    }
  }

  public setEndInstance(instance: Instance): void {
    this.endDatePickerInstance = instance;
  }

  public setStartInstance(instance: Instance): void {
    this.startDatePickerInstance = instance;
  }

  public onChangeFixedDate(fixedDate): void {
    if (fixedDate.value === 'custom') {
      this.searchEventsForm.reset();
    } else {
      const interval = new DateInterval();
      interval[fixedDate.value](fixedDate.value);
      this.dateInterval.next(interval);
    }
  }
}
