export const inAlphabeticalOrder = (a, b) => {
  let before;
  let after;
  if (a.fullName && b.fullName) {
    before = a.fullName.toUpperCase();
    after = b.fullName.toUpperCase();
  }

  if (a.type && b.type) {
    before = a.name.toUpperCase();
    after = b.name.toUpperCase();
  }

  if (a.kit_ref && b.kit_ref) {
    before = a.kit_ref.toUpperCase();
    after = b.kit_ref.toUpperCase();
  }

  if (a.product_name && b.product_name) {
    before = a.product_name.toUpperCase();
    after = b.product_name.toUpperCase();
  }

  if (a.name && b.name) {
    before = a.name.toUpperCase();
    after = b.name.toUpperCase();
  }

  let comparison = 0;
  if (before > after) {
    comparison = 1;
  } else if (before < after) {
    comparison = -1;
  }
  return comparison;
};
