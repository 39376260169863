import { Pipe, PipeTransform } from '@angular/core';
import { trad } from './french.trad';
@Pipe({ name: 'toFrench' })
export class ToFrench implements PipeTransform {
  transform(value: string, gender: string, number: string) {
    return trad[value]
      ? `${trad[value]}${gender === 'f' ? 'e' : ''}${
          number === 'pl' ? 's' : ''
        }`
      : value;
  }
}
