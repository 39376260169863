import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from 'Extension/services/auth/auth.service';
import { INavItem } from '../NavItem.interface';
import { environment } from 'Environments/environment';

@Component({
  selector: 'extension-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  navLinks: INavItem[];
  extensionID;
  extensionLink;
  isLogging;

  public staging: boolean = environment.staging;

  constructor(
    private sanitizer: DomSanitizer,
    private authService: AuthService,
  ) {
    this.navLinks = [
      {
        label: 'Disponibilités',
        shape: 'blocks-group',
        link: '/search_availaible_kits',
        permissions: ['read:search', 'ui:disponibility'],
        index: 0,
      },
      {
        label: 'Réservation',
        shape: 'event',
        link: '/book_kit',
        permissions: ['add:events', 'read:search'],
        index: 1,
      },
      {
        label: 'FAQ',
        shape: 'faq',
        link: '/faq',
        permissions: ['ui:faq'],
        index: 2,
      },
    ];
  }

  async ngOnInit() {
    this.authService.isAuthenticated$.subscribe((isAuth) => {
      this.isLogging = isAuth;
    });
    if (environment.isExtension) {
      this.extensionID = chrome.i18n.getMessage('@@extension_id');
      this.extensionLink = this.sanitizer.bypassSecurityTrustUrl(
        `chrome-extension://${this.extensionID}/index.html#/blank-component`,
      );
    } else {
      this.extensionLink = '#/blank-component';
    }
  }

  public logout() {
    this.authService.logout();
  }
}
