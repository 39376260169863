import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EventsApiService } from 'Extension/services/api/events/events.service';

@Component({
  selector: 'extension-emoji, [extension-emoji]',
  templateUrl: './emoji.component.html',
  styleUrls: ['./emoji.component.scss'],
})
export class EmojiComponent {
  @Input() notification: any;
  @Input() event: any;

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private eventsService: EventsApiService) {}

  public async resetSurvey(): Promise<void> {
    await this.eventsService.resetSatisfactionSurvey(this.event._id);
    this.refresh.emit(true);
  }
}
