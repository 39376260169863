import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  CanActivate,
} from '@angular/router';
import { Observable, race } from 'rxjs';
import { AuthService } from './auth.service';
import { skip, delay } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from 'Environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(private auth: AuthService, public router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return new Promise((resolve, reject) => {
      race(
        this.auth.isAuthenticated$.pipe(skip(1)),
        this.auth.isAuthenticated$.pipe(delay(2000)),
      ).subscribe((isAuth) => {
        if (environment.isExtension && isAuth === false) {
          chrome.tabs.create({
            url: `chrome-extension://${chrome.i18n.getMessage(
              '@@extension_id',
            )}/index.html#/noAuth`,
          });
          reject();
        } else if (!environment.isExtension && isAuth === false) {
          this.router.navigate(['noAuth']);
          reject();
        } else {
          this.auth.accessTokenSubject$.subscribe((access_token) => {
            let arrayPermissions = [];
            if (access_token !== null) {
              arrayPermissions = jwt_decode(
                JSON.stringify(access_token, null, 2),
              ).permissions;
            }
            this.auth.userProfile$.subscribe((user) => {
              if (
                user &&
                (arrayPermissions.includes('update:events') ||
                  user[`${environment.auth0Audience}/roles`].includes(
                    'Magasin',
                  ))
              ) {
                this.router.navigate(['search_availaible_kits']);
              } else {
                this.router.navigate(['blank-component/dashboard-animation']);
              }
            });
          });
          resolve(true);
        }
      });
    });
  }
}
