import { Injectable } from '@angular/core';
import {
  NjoyRuntimeRequest as NjoyRequest,
  MappyRuntimeSuggestRequest as MappySuggestRequest,
} from 'Utils/requests';

@Injectable({
  providedIn: 'root',
})
export class ClientsApiService {
  constructor() {}

  public async getClient(_id) {
    const { data } = await NjoyRequest.get(`/clients/${_id}`);
    return data;
  }

  public async getClientFromPipedriveId(_id, type) {
    const { data } = await NjoyRequest.get(`/search/clients/${type}/${_id}`);
    return data;
  }

  public async searchClients(regex) {
    const { data } = await NjoyRequest.get(
      `/search/clients?name=${JSON.stringify({
        $regex: regex,
      })}`,
    );
    return data;
  }

  public async getClientPosition(client) {
    const { lat: client_lat, lng: client_lng } = (
      await MappySuggestRequest.get(
        `suggest?q=${client.address.formatted_address}&f=places&max_results=1`,
      )
    ).data.suggests[0];
    return { lat: client_lat, lng: client_lng };
  }
}
