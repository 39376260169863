import {
  Component,
  OnInit,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { EventsApiService } from 'Extension/services/api/events/events.service';
import { Subject } from 'rxjs';
import { MappyService } from 'Extension/services/mappy/mappy.service';

export const DETAILED_ADDRESS_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DetailedAddressComponent),
  multi: true,
};

@Component({
  selector: 'extension-detailed-address',
  providers: [DETAILED_ADDRESS_VALUE_ACCESSOR],
  templateUrl: './detailed-address.component.html',
  styleUrls: ['./detailed-address.component.scss'],
})
export class DetailedAddressComponent
  implements OnInit, OnChanges, ControlValueAccessor {
  @Input() setAddressValue: any;
  suggest = new Subject();
  address = new Subject();

  detailedAddressForm = new FormGroup({
    admin_area_level_1: new FormControl(null),
    admin_area_level_2: new FormControl(null),
    country: new FormControl(''),
    formatted_address: new FormControl(''),
    lat: new FormControl(null),
    lng: new FormControl(null),
    locality: new FormControl(''),
    postal_code: new FormControl(''),
    route: new FormControl(''),
    street_number: new FormControl(''),
    additional: new FormControl(''),
  });

  street_number: string;
  route: string;
  postal_code: string;
  locality: string;
  country: string;
  formatted_address: string;
  additional: string;

  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;

  constructor(
    private mappyService: MappyService,
    private eventsApiService: EventsApiService,
  ) {
    this._onChange = (_: any) => {};
    this._onTouched = (_: any) => {};
    this.street_number = '';
    this.route = '';
    this.postal_code = '';
    this.locality = '';
    this.country = '';
    this.formatted_address = '';
    this.additional = '';
  }

  ngOnInit() {
    this.onFormChange();
    this.autocompleteWithSuggestAddress();
    this.onFormattedAddressChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.setAddressValue && changes.setAddressValue.currentValue) {
      switch (true) {
        case changes.setAddressValue.currentValue.from === 'suggest':
          this.suggest.next(changes.setAddressValue.currentValue.value);
          break;
        case changes.setAddressValue.currentValue.from === 'booking' ||
          changes.setAddressValue.currentValue.from === 'event':
          this.completeForm(changes.setAddressValue.currentValue.value);
          break;
      }
    }
  }

  writeValue(data: any): void {
    this.completeForm(data);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  async addCoordinates(address): Promise<void> {
    const coordinates = address
      ? await this.mappyService.getCoordinates(address)
      : { lat: null, lng: null };
    this.detailedAddressForm.get('lat').setValue(coordinates.lat);
    this.detailedAddressForm.get('lng').setValue(coordinates.lng);
  }

  onFormattedAddressChanges() {
    this.detailedAddressForm
      .get('formatted_address')
      .valueChanges.subscribe(async (value) => {
        await this.addCoordinates(value);
      });
  }

  onFormChange() {
    this.detailedAddressForm.valueChanges
      .pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe(async (value) => {
        this._onChange(value);
      });
  }

  private autocompleteWithSuggestAddress(): void {
    this.suggest
      .pipe(debounceTime(200), distinctUntilChanged())
      .subscribe((val: any) => {
        if (val && val.properties && val.geometry) {
          this.detailedAddressForm
            .get('admin_area_level_1')
            .setValue(val ? val.properties.context.split(',')[2] : null);
          this.detailedAddressForm
            .get('admin_area_level_2')
            .setValue(val ? val.properties.context.split(',')[1] : null);
          this.detailedAddressForm.get('country').setValue(val ? 'France' : '');
          this.detailedAddressForm
            .get('formatted_address')
            .setValue(val ? val.properties.label : '');
          this.detailedAddressForm
            .get('lat')
            .setValue(val ? val.geometry.coordinates[1] : null);
          this.detailedAddressForm
            .get('lng')
            .setValue(val ? val.geometry.coordinates[0] : null);
          this.detailedAddressForm
            .get('locality')
            .setValue(val ? val.properties.city : '');
          this.detailedAddressForm
            .get('postal_code')
            .setValue(val ? val.properties.postcode : '');
          this.detailedAddressForm
            .get('route')
            .setValue(
              val && val.properties.street ? val.properties.street : '',
            );
          this.detailedAddressForm
            .get('street_number')
            .setValue(
              val && val.properties.housenumber
                ? val.properties.housenumber
                : '',
            );
        } else {
          this.completeForm(val);
        }
      });
  }

  private completeForm(address) {
    this.street_number = address ? address.street_number : '';
    this.route = address ? address.route : '';
    this.postal_code = address ? address.postal_code : '';
    this.locality = address ? address.locality : '';
    this.country = address ? address.country : '';
    this.formatted_address = address ? address.formatted_address : '';
    this.additional = address && address.additional ? address.additional : '';
    this.detailedAddressForm
      .get('street_number')
      .setValue(address ? address.street_number : '');
    this.detailedAddressForm
      .get('route')
      .setValue(address ? address.route : '');
    this.detailedAddressForm
      .get('locality')
      .setValue(address ? address.locality : '');
    this.detailedAddressForm
      .get('country')
      .setValue(address ? address.country : '');
    this.detailedAddressForm
      .get('postal_code')
      .setValue(address ? address.postal_code : '');
    this.detailedAddressForm
      .get('formatted_address')
      .setValue(address ? address.formatted_address : '');
    this.detailedAddressForm
      .get('admin_area_level_1')
      .setValue(address ? address.admin_area_level_1 : null);
    this.detailedAddressForm
      .get('admin_area_level_2')
      .setValue(address ? address.admin_area_level_2 : null);
    this.detailedAddressForm.get('lat').setValue(address ? address.lat : null);
    this.detailedAddressForm.get('lng').setValue(address ? address.lng : null);
    this.detailedAddressForm
      .get('additional')
      .setValue(address ? address.additional : null);
  }
}
