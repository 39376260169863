import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClarityModule } from '@clr/angular';
import { CommonModule } from '@angular/common';
import { ExtensionRoutingModule } from './extension-routing.module';
import { ExtensionComponent } from './extension.component';
import { NgxFlatpickrModule } from 'ngx-flatpickrjs';
import { AuthComponent } from './layout/auth/auth.component';
import { BlankComponent } from './layout/blank/blank.component';

import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';

import { ClientsApiService } from 'Extension/services/api/clients/clients.service';
import { ProductsApiService } from 'Extension/services/api/products/products.service';
import { SearchAvailabilitiesApiService } from 'Extension/services/api/searchAvailabilities/search-availabilities.service';
import { EventsApiService } from 'Extension/services/api/events/events.service';
import { AddressService } from 'Extension/services/api/address/address.service';
import { DealsApiService } from 'Extension/services/api/deals/deals.service';
import { SharedModule } from 'Shared/shared.module';
import { DropDownDirective } from 'Extension/layout/blank/dropdown.directive';

@NgModule({
  imports: [
    ReactiveFormsModule,
    CommonModule,
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    ClarityModule,
    ExtensionRoutingModule,
    HttpClientModule,
    NgSelectModule,
    NgxFlatpickrModule,
    AutocompleteLibModule,
    NgxSpinnerModule,
    ToastrModule.forRoot(),
    SharedModule,
  ],
  exports: [FormsModule, AutocompleteLibModule],
  declarations: [
    BlankComponent,
    ExtensionComponent,
    AuthComponent,
    DropDownDirective,
  ],
  providers: [
    ClientsApiService,
    ProductsApiService,
    SearchAvailabilitiesApiService,
    EventsApiService,
    AddressService,
    DealsApiService,
  ],
  bootstrap: [ExtensionComponent],
})
export class ExtensionModule {}
