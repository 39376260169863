import {
  Component,
  OnInit,
  forwardRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Observable, Subject, concat, of, from } from 'rxjs';
import { tap, switchMap, map, filter } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AddressService } from 'Extension/services/api/address/address.service';

export const SUGGEST_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SuggestAddressComponent),
  multi: true,
};

@Component({
  selector: 'extension-suggest-address',
  providers: [SUGGEST_VALUE_ACCESSOR],
  templateUrl: './suggest-address.component.html',
  styleUrls: ['./suggest-address.component.scss'],
})
export class SuggestAddressComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Input() getPreviousAddress;
  suggestAddresses: Observable<
    any | any[] | Observable<any> | Observable<any[]>
  >;
  locationLoading = false;
  locationInput = new Subject();
  initValue: any;

  address: any = null;

  private _onChange: (_: any) => void;
  private _onTouched: (_: any) => void;

  constructor(private addressService: AddressService) {
    this._onChange = (_: any) => {};
    this._onTouched = (_: any) => {};
  }

  ngOnInit(): void {
    this.loadSuggestAdresses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.getPreviousAddress && changes.getPreviousAddress.currentValue) {
      this.initValue = changes.getPreviousAddress.currentValue;
    }
    this.loadSuggestAdresses();
  }

  writeValue(value: any): void {
    if (value) {
      this.address = value;
    } else {
      this.address = {};
      this.address.properties = {};
      this.address.properties.label = null;
    }
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  onChangeAddress(address): void {
    this._onChange(address);
  }

  private loadSuggestAdresses(): void {
    this.suggestAddresses = concat(
      of(this.initValue ? this.initValue : []),
      this.locationInput.pipe(
        tap(() => (this.locationLoading = true)),
        filter((value: string) => value && value.length > 3),
        switchMap((value: string) =>
          from(this.addressService.getAPIAddressDataGouv(value)).pipe(
            map((results) => results.features),
            tap(() => (this.locationLoading = false)),
          ),
        ),
      ),
    );
  }
}
